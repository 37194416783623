import React, { ReactNode, useEffect, useState } from "react";
import { Row, Col } from "antd";
import Image from "next/image";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../../../../public/locales/en.json";
import translationDE from "../../../../public/locales/de.json";

interface AuthLayoutProps {
  children: ReactNode;
}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    de: {
      translation: translationDE,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>("en");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("languageSelected") || "en";
    setCurrentLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage);
  }, []);

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === "en" ? "de" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("languageSelected", newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="h-screen">
      <Row justify="center" align="middle" className="h-full">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="h-full bg-cover bg-center"
          style={{ backgroundImage: "url(/bg_home_img.jpg)" }}
        >
          <div className="flex flex-col h-full items-center justify-between lg:justify-end">
            <div className="flex flex-col md:w-4/6 lg:w-3/4 bg-white py-8 px-24 rounded-md shadow-lg lg:hidden mt-8 lg:mt-0">
              <div className="flex items-center justify-end mb-8">
                <Image
                  src="/language-icon.svg"
                  alt="Logo"
                  width={15}
                  height={15}
                />
                <div
                  className={`${
                    currentLanguage === "en"
                      ? "text-[#0298A0] text-lg font-medium"
                      : "text-black-500 text-lg font-medium"
                  } mx-2 cursor-pointer`}
                  onClick={handleLanguageChange}
                >
                  EN
                </div>
                <div className="mr-2">|</div>
                <div
                  className={`${
                    currentLanguage !== "en"
                      ? "text-[#0298A0] text-lg font-medium"
                      : "text-black-500 text-lg font-medium"
                  } mr-2 cursor-pointer`}
                  onClick={handleLanguageChange}
                >
                  DE
                </div>
              </div>
              {children}
            </div>
            <div className="z-10 bg-[#EDFFFE] opacity-80 w-full h-[15%] flex justify-center items-center bottom-0 left-0 lg:mb-[5%] mb-8">
              <Image
                data-testid="image"
                src="/logo.svg"
                alt="Logo"
                width={300}
                height={300}
              />
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="w-full h-full flex justify-center items-center"
        >
          <div className="w-4/6 h-full flex flex-col justify-center items-center">
            <div className="flex items-center justify-end w-full mt-[10%] cursor-pointer">
              <Image
                src={"/language-icon.svg"}
                alt="Logo"
                width={15}
                height={15}
                className="mr-2"
              />
              <div
                className={`${
                  currentLanguage === "en"
                    ? "text-[#0298A0] text-lg font-medium"
                    : "text-black-500 text-lg font-medium"
                } mx-2 cursor-pointer`}
                onClick={handleLanguageChange}
              >
                EN
              </div>
              <div className="mr-2">|</div>
              <div
                className={`${
                  currentLanguage !== "en"
                    ? "text-[#0298A0] text-lg font-medium"
                    : "text-black-500 text-lg font-medium"
                } cursor-pointer`}
                onClick={handleLanguageChange}
              >
                DE
              </div>
            </div>
            <div className="flex-col h-full justify-center items-center w-full md:flex md:items-center md:justify-center">
              <div className="flex flex-col w-full lg:w-full bg-white md:rounded-md md:shadow-lg lg:rounded-none lg:shadow-none">
                {children}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthLayout;

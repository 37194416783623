import React, { useState, useEffect } from "react";
import { Layout, Dropdown, Menu } from "antd";
import { logout } from "src/Utils/logoutUtil";
import CustomModal from "@/components/Atoms/CustomModal";
import Image from "next/image";
import { usePathname, useRouter } from 'next/navigation';
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import translationEN from "../../../../public/locales/en.json";
import translationDE from "../../../../public/locales/de.json";
import serviceUtil from "src/Utils/serviceUtil/serviceUtil";

const { Header } = Layout;

interface SubMenu {
  Menu_Label: string;
  isActive: boolean;
  Menu_URL: string;
}

interface MenuItem {
  Menu_Label: string;
  isActive: boolean;
  Menu_Icon: string;
  Menu_URL: string;
  subMenu?: SubMenu[];
}

interface AppHeaderProps {
  selectedSubMenu: string | null;
  setSelectedSubMenu: (url: string) => void;
  updateTranslatedMenu: (menu: MenuItem[]) => void;
}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    de: {
      translation: translationDE,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const AppHeader: React.FC<AppHeaderProps> = ({ selectedSubMenu, setSelectedSubMenu, updateTranslatedMenu }) => {
  const [username, setUsername] = useState<string | null>("");
  const [email, setEmail] = useState<string | null>("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [hoveredSubMenu, setHoveredSubMenu] = useState<string | null>(null);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>("en");
  const [menu, setMenu] = useState<MenuItem[]>([]);
  const pathName = usePathname();
  const router = useRouter();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const email = localStorage.getItem("email");

    setUsername(`${firstName} ${lastName}`);
    setEmail(email);

    const menuData = localStorage.getItem("userMenu");
    if (menuData) {
      setMenu(JSON.parse(menuData));
    }

    // Set the selected submenu based on the current URL
    setSelectedSubMenu(pathName);
  }, [pathName, setSelectedSubMenu]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("languageSelected") || "en";
    setCurrentLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage);
  }, []);

  const handleLanguageChange = async () => {
    const newLanguage = currentLanguage === "en" ? "de" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("languageSelected", newLanguage);
    i18n.changeLanguage(newLanguage);
    const accessToken =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('accessToken')
        : '';
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      lang: localStorage.getItem('languageSelected') ? localStorage.getItem('languageSelected') : 'en'
    };

    try {
      const response = await serviceUtil.get(
        `${process.env.NEXT_PUBLIC_API_URL}/identity/user/menu`, {}, headers,
      );

      if (response?.status === 200) {
        localStorage.setItem("userMenu", JSON.stringify(response.data.userData));
        const menuData = localStorage.getItem("userMenu");
        if (menuData) {
          setMenu(JSON.parse(menuData));
          updateTranslatedMenu(JSON.parse(menuData))
        }
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        // Handle unauthorized error
        window.location.assign("/");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginTime");
      }
    }

  };

  const handleLogout = () => {
    setIsModalVisible(true);
  };

  const handleOkClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    logout();
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible);
  };

  const handleSubMenuItemClick = (key: string) => {
    const findMenuItem = (items: MenuItem[], targetKey: string): MenuItem | undefined => {
      for (const item of items) {
        if (item.Menu_URL === targetKey) {
          return item;
        }
        if (item.subMenu) {
          const found = findMenuItem(item?.subMenu, targetKey);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    };

    const selectedItem = findMenuItem(menu, key);

    if (selectedItem && !selectedItem.isActive) {
      return;
    }

    setSelectedSubMenu(key);
    router.push(key);
  };

  const menuItems = [
    {
      key: "profile",
      label: "My Profile",
      icon: "profile",
      disabled: true,
    },
    {
      key: "settings",
      label: "Settings",
      icon: "setting",
      disabled: true,
    },
    {
      key: "logout",
      label: "Logout",
      icon: "logout",
      disabled: false,
      onClick: handleLogout,
    },
  ];

  const profileMenu = (
    <Menu>
      <Menu.Item
        key="userInfo"
        style={{ marginBottom: "16px" }}
        icon={
          <div className="bg-[#3FC1C0] mr-8 rounded-full h-8 w-8 flex items-center justify-center text-white md:text-xs lg:text-md">
            {username?.split(" ").map(word => word.charAt(0).toUpperCase()).join("")}
          </div>
        }
        className="mb-4"
      >
        <div className="md:text-sm lg:text-md font-medium ml-4">{username}</div>
        <div className="md:text-xs lg:text-sm text-gray-600 ml-4">{email}</div>
      </Menu.Item>
      {menuItems.map((item) => (
        <Menu.Item
          key={item.key}
          style={{ marginBottom: "16px" }}
          icon={
            <Image
              src={`/${item.icon}${
                hoveredItem === item.key
                  ? "-hovered"
                  : item.disabled
                  ? "-disabled"
                  : ""
              }.svg`}
              alt={`${item.icon}-icon`}
              className="w-6 h-6 ml-[4px]"
              width={0}
              height={0}
            />
          }
          className="mb-4"
          disabled={item.disabled}
          onClick={item.onClick}
          onMouseEnter={() => setHoveredItem(item.key)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div
            className={`md:text-sm lg:text-md font-medium ml-4 ${
              hoveredItem === item.key
                ? "text-[#1D4E89]"
                : item.disabled
                ? "text-[#737373]"
                : "text-inherit"
            }`}
          >
            {t(item.label)}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const renderSubMenus = () => {
    return menu.flatMap(item => {
      if (item.subMenu && item.subMenu.length > 0) {
        const isAnySubMenuVisited = item.subMenu.some(subItem => subItem.Menu_URL === pathName);
        if (isAnySubMenuVisited) {
          return item.subMenu.map(subItem => (
            <div
              key={subItem.Menu_URL}
              className={`md:mr-1 lg:mr-2 md:text-sm lg:text-md ${
                subItem.Menu_URL === selectedSubMenu && subItem.isActive
                  ? "text-[#1D4E89] font-semibold bg-[#F4F9FF] md:p-2 lg:p-3 cursor-pointer"
                  : hoveredSubMenu === subItem.Menu_URL && subItem.isActive
                  ? "text-[#1D4E89] md:p-2 lg:p-3 cursor-pointer"
                  : !subItem.isActive 
                  ? "text-[#737373] md:p-2 lg:p-3 cursor-not-allowed"
                  : "text-black md:p-2 lg:p-3 cursor-pointer"
              }`}
              onClick={() => handleSubMenuItemClick(subItem.Menu_URL)}
              onMouseEnter={() => setHoveredSubMenu(subItem.Menu_URL)}
              onMouseLeave={() => setHoveredSubMenu(null)}
            >
              {subItem.Menu_Label}
            </div>
          ));
        }
      }
      return [];
    });
  };

  return (
    <Header
      style={{
        padding: 0,
        background: "#fff",
        position: "sticky",
        top: 0,
        zIndex: 100,
        height: "70px",
        width: "100%",
        borderBottom: "1px solid #BBCBDE",
      }}
      className="flex justify-between items-center"
    >
      <div className="flex items-center md:ml-4 lg:ml-6 w-4/6">
        {renderSubMenus()}
      </div>
      <div className="flex items-center justify-end w-2/6">
        <Image src={`/bell.svg`} alt={`bell-icon`} className="w-6 h-6 md:mr-4 lg:mr-6" width={0} height={0}/>
        <div className="flex items-center md:mr-4 lg:mr-6">
          <Image
            src={"/language-icon.svg"}
            alt="Logo"
            width={15}
            height={15}
            className="mr-2"
          />
          <div
            className={`${
              currentLanguage === "en"
                ? "text-[#0298A0] md:text-sm lg:text-lg font-medium cursor-pointer"
                : "text-black-500 md:text-sm lg:text-lg font-medium cursor-pointer"
            } mr-2`}
            onClick={handleLanguageChange}
          >
            EN
          </div>
          <div className="mr-2">|</div>
          <div
            className={`${
              currentLanguage !== "en"
                ? "text-[#0298A0] md:text-sm lg:text-lg font-medium cursor-pointer"
                : "text-black-500 md:text-sm lg:text-lg font-medium cursor-pointer"
            }`}
            onClick={handleLanguageChange}
          >
            DE
          </div>
        </div>
        <Dropdown
          overlay={profileMenu}
          trigger={["click"]}
          placement="bottomRight"
          onOpenChange={handleDropdownVisibleChange}
          open={dropdownVisible}
        >
          <div className="cursor-pointer md:mr-4 lg:mr-8 flex items-center" data-testid="user-name-icon">
            <div className="bg-[#3FC1C0] rounded-full md:h-7 md:w-7 lg:h-9 lg:w-9 flex items-center justify-center text-white md:text-sm lg:text-lg">
              {username?.split(" ").map(word => word.charAt(0).toUpperCase()).join("")}
            </div>
          </div>
        </Dropdown>
        <CustomModal
          isVisible={isModalVisible}
          onCancel={closeModal}
          onOk={(e: React.MouseEvent<HTMLButtonElement>) => handleOkClick(e)}
          title={""}
          description={t("Are you sure you want to logout?")}
          iconType={"Warning"}
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      </div>
    </Header>
  );
};

export default AppHeader;

"use client";
import { ProviderRedux } from "../GlobalRedux/provider";
import "../../styles/globals.css";
import { usePathname } from "next/navigation";
import React from "react";
import MainLayout from "../components/Organisms/MainLayout/MainLayout";
import AuthLayout from "@/components/Organisms/AuthLayout/AuthLayout";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathName = usePathname();
  const authPages = [
    "/",
    "/login",
    "/auth/login",
    "/auth/forgot-password",
    "/auth/registration",
    "/auth/verify-email",
    "/auth/reset-password",
  ];
  const isAuthPage = authPages.includes(pathName);

  return (
    <html lang="en">
      <head></head>
      <body className="scroll-container">
        <ProviderRedux>
          {!isAuthPage ? (
            <MainLayout>{children}</MainLayout>
          ) : (
            pathName === "/auth/verify-email" ? <div>
            {children}
          </div>: <AuthLayout>{children}</AuthLayout>
          )}
        </ProviderRedux>
      </body>
    </html>
);
}

import React from "react";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons"; // Import the Ant Design icon
import PrimaryButton from "./primaryButton";
import { useTranslation } from "react-i18next";

interface CustomModalProps {
  isVisible: boolean;
  title: string;
  description: string;
  onOk: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
  iconType: "Warning" | "Error" | "Success" | "Info" | string;
  primaryButtonText: string;
  secondaryButtonText?: string;
}

const iconUrls = {
  Warning: "/warning.svg",
  Error: "/error.svg",
  Success: "/success.svg",
};

const CustomModal: React.FC<CustomModalProps> = ({
  isVisible,
  title,
  description,
  onOk,
  onCancel,
  iconType,
  primaryButtonText,
  secondaryButtonText,
}) => {
  const iconUrl = iconUrls[iconType];

  // Determine the width based on content length
  const determineModalWidth = () => {
    if (description?.length < 150) {
      return "520px";
    } else {
      return "100%";
    }
  };
  const { t } = useTranslation();
  return (
    <Modal
      data-testid="custom-modal"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      centered
      className="w-full"
      style={{
        maxWidth: "840px",
        width: determineModalWidth(),
        margin: "0 auto",
        minWidth: "320px",
      }}
      width={determineModalWidth()}
    >
      <div className="flex flex-col justify-center items-center py-4 px-6 text-center">
        {iconType === "Info" ? (
          <InfoCircleOutlined
            style={{ fontSize: "78px", color: "#276EF1" }}
            className="mb-4"
            data-testid="modal-icon"
          />
        ) : (
          <img
            src={iconUrl}
            alt="ModalIcon"
            className="mb-4"
            data-testid="modal-icon"
          />
        )}
        <div
          className="text-xl text-black font-medium mb-4"
          data-testid="modal-title"
        >
          {t(title)}
        </div>
        <p
          className="text-[#282828] text-lg mb-4"
          data-testid="modal-description"
        >
          {t(description)}
        </p>
        <div
          className={`${
            secondaryButtonText ? "justify-between" : "justify-center"
          } flex items-center w-2/4`}
          data-testid="modal-buttons"
        >
          <PrimaryButton
            theme="secondary"
            secondaryColor={{
              backgroundColor: "transparent",
              color: "#1D4E89",
              borderColor: "#1D4E89",
            }}
            disabled={false}
            height={"2vmin"}
            onClick={onOk}
            className="text-sm sm:text-base md:text-md lg:text-lg xxl:text-xl py-5 px-8"
            data-testid='yes-button'
          >
            {t(primaryButtonText)}
          </PrimaryButton>
          {secondaryButtonText ? (
            <PrimaryButton
              theme="secondary"
              secondaryColor={{
                backgroundColor: "transparent",
                color: "#1D4E89",
                borderColor: "#1D4E89",
              }}
              disabled={false}
              height={"2vmin"}
              onClick={onCancel}
              className="text-sm sm:text-base md:text-md lg:text-lg xxl:text-xl py-5 px-8"
            >
              {t(secondaryButtonText)}
            </PrimaryButton>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
